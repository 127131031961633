<template>
    <div>
        <products v-if="state.step === 1" @next="next" :reference="reference" />
        <input-raw-material v-if="state.step === 2" @next="next" @back="back" :reference="reference" />
        <other-cost v-if="state.step === 3" @next="next" @back="back" :reference="reference" />
        <produce-summary v-if="state.step === 4" @back="back" :reference="reference" />
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import ShortUniqueId from 'short-unique-id'
import Products from './Products'
import InputRawMaterial from './InputRawMaterial'
import OtherCost from './OtherCost'
import ProduceSummary from './ProduceSummary.vue'

export default {
    name: 'Produce',
    components: { Products, InputRawMaterial, OtherCost, ProduceSummary },

    setup() {
        const store = useStore()
        const produce = computed(() => store?.state.Production.produce)
        const reference = new ShortUniqueId({ length: 6 }).randomUUID()

        const state = reactive({
            step: 1,
            produce: {
                reference: reference,
                output: {
                    products: [
                        {
                            name: '',
                            product: '',
                            price: 0,
                            quantity: 1,
                            instock: '',
                            measurement: '',
                            sku: ''
                        }
                    ],
                    date: moment().format('YYYY-MM-DD'),
                    total: 0,
                    description: ''
                },
                input: {
                    rawMaterials: [
                        {
                            name: '',
                            product: '',
                            price: 0,
                            quantity: 1,
                            instock: '',
                            measurement: '',
                            sku: ''
                        }
                    ],
                    date: moment().format('YYYY-MM-DD'),
                    total: 0,
                    description: ''
                },
                additionalCosts: {
                    items: [
                        {
                            name: '',
                            amount: 0,
                            description: ''
                        }
                    ],
                    total: 0,
                    paymentType: 'Cash',
                }
            }
        })

        const next = value => {
            state.step = value
        }

        const back = value => {
            state.step = value
        }

        onMounted(() => {
            store.dispatch('Production/setProduce', state.produce)
        })

        return {
            state,
            reference,
            produce,
            next,
            back
        }
    }
}
</script>

<style scoped></style>
