<template>
    <div class="w-90 center mt4-l mt3 " style="background-color: #FBFCFE;">
        <div class="flex flex-wrap justify-between pa3 pv4" style=" font-size: 18px">
            <div class="pb3 pb0-l">
                <span class="b font-w2 " style="color: #132c8c;">Other production cost </span><br>
              <div class="w-100">Input the list of other direct cost associated with this production batch </div>
            </div>
             <div class="f5">Production ID: {{ reference }}</div>
        </div>

        <div v-for="(data, index) in state.additionalCosts.items" :key="index" class="pa3 flex flex-wrap justify-between">
            <div class="flex flex-column w-30-l w-50 pb3 pb0-l ">
                <label class="pb2 b">Type</label>
                <select v-model="data.name" class="product-input radius8" required>
                    <option selected value="">-Choose Type-</option>
                    <option v-for="expense of expenses" :key="expense" :value="expense.value">{{ expense.name }}</option>
                </select>
            </div>
            <div class=" flex flex-column w-30-l w-50  pb3 pb0-l">
                <label class="pb2 b">Amount</label>
                <input 
                    type="number" 
                    @input="calculateAdditionalCostsTotal"
                    class="product-input radius8" 
                    v-model="data.amount" 
                    placeholder="0.00" 
                    required 
                />
            </div>
            <div class=" flex flex-column w-30-l w-50  pb3 pb0-l">
                <label class="pb2 b">Description</label>
                <textarea
                    type="text" 
                    class="product-input radius8" 
                    style="height: 70px;"
                    v-model="data.description" 
                    placeholder="Description" >
                </textarea>
            </div>
            <div class="pt5" @click.prevent="removeCost(index)" style="cursor: pointer">
                <img :src="require('@/assets/images/trash.svg')" alt="Delete" />
            </div>
        </div>

        <div class="tr b pa3">
            <div class="">
                <a href="" @click.prevent="addMoreCost" style="color: #132c8c"> + Add more cost </a>
            </div>
        </div>

        <!-- upfront due date -->
        <div class="flex justify-between pa3">
            <div class="flex flex-column w-50 pr2-l pr1">
                <label class="pb2 b">Total</label>
                <input class="product-input radius8" type="text" v-model="state.additionalCosts.total" placeholder="" required />
            </div>
            <!-- <div class="flex flex-column w-50 pl2-l pl1">
                <label class="pb2">Payment type</label>
                <select class="select-inline" v-model="state.additionalCosts.paymentType">
                    <option value="Cash" selected>Cash</option>
                    <option v-for="(bank, index) in bankAccounts" :key="index">{{ bank?.aliasName || bank?.bankName }}</option>
                </select>
            </div> -->
        </div>

        <div class="flex justify-between">
            <div>
                <a href="" class="b pa3 pt2" @click.prevent="back(2)">&larr; Back to production input</a>
            </div>
            <div class="pa3 flex">
                <router-link :to="{ name: 'RawMaterial' }">
                    <button class="btn2">Cancel</button>
                </router-link>
                <a href="" @click.prevent="next(4)">
                    <button class="ml2">Next</button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'OtherCost',
    props: { reference: String },

    setup(_, { emit }) {
        const store = useStore()
        const produceAdditionalCosts = computed(() => store?.state.Production.produce.additionalCosts)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)

        const state = reactive({
            additionalCosts: {
                items: [
                    {
                        name: '',
                        amount: 0,
                        description: ''
                    }
                ],
                total: 0,
                paymentType: 'Cash',
            }
        })

        const expenses = [
            { value: 'transport', name: 'Transportation' },
            { value: 'commission', name: 'Commission' },
            // { value: 'input_raw_materials', name: 'Raw Materials' },
            // { value: 'ads', name: 'Advertisement' },
            { value: 'packaging', name: 'Packaging & Branding' },
            // { value: 'discount', name: 'Discount Given' },
            // { value: 'fees', name: 'Fees' },
            { value: 'rent', name: 'Rent' },
            { value: 'utility', name: 'Utility' },
            { value: 'interest_expense', name: 'Interest Expense' },
            { value: 'others', name: 'Other Expenses' },
            { value: 'home_office_cost', name: 'Stationary' },
            { value: 'furniture_equipment_machinery', name: 'Furniture/Equipment/Machinery' },
            { value: 'office_supplies', name: 'Office Supplies/Consumables' },
            { value: 'advertising_marketing', name: 'Advertising & Marketing' },
            { value: 'website_software', name: 'IT Expenses' },
            { value: 'entertainment', name: 'Entertainment' },
            { value: 'business_meals_travel_expense', name: 'Business Meals & Travel Expense' },
            { value: 'salary', name: 'Salary/Payroll' },
            { value: 'vehicle_expenses', name: 'Vehicle Cost/Fuel' },
            { value: 'taxes', name: 'Taxes' },
            { value: 'business_insurance', name: 'Business Insurance' },
            { value: 'license_registration', name: 'License & Registration' },
            { value: 'professional_business_fees', name: 'Professional & Business Fees' },
            { value: 'trainings', name: 'Trainings' },
            { value: 'input_raw_materials', name: 'General Accessories/Raw materials' },
            { value: 'bank', name: 'Bank Fees/Charges' },
            { value: 'electricity', name: 'Electricity' },
            { value: 'telephone', name: 'Communication/Airtime' },
            { value: 'csr', name: 'Gift and Donations' },
            { value: 'maintenance_repairs', name: 'Maintenance/Repairs' },
            { value: 'cost_of_goods_sold', name: 'Cost of Service Rendered' },
            { value: 'employee_benefits', name: 'Employee benefits' },
            { value: 'generator', name: 'Generator - Diesel/Fuel' },
            { value: 'internet', name: 'Internet' },
            { value: 'periodicals', name: 'Periodicals (Newspaper, Journals)' },
            { value: 'printing', name: 'Printing' },
            { value: 'utility_cable_tv', name: 'Utility - Cable TV' },
            { value: 'utility_water_and_disposal', name: 'Utility - Waste & Disposal' },
            { value: 'utility_others', name: 'Utility - Others' },
            { value: 'direct_labour', name: 'Direct Labour (Cost of Sales)' },
            { value: 'direct_utility', name: 'Direct Utility (Cost of Sales)' },
        ]

        watch(
            () => produceAdditionalCosts.value,
            () => {
                state.additionalCosts = produceAdditionalCosts.value
            }
        )

        const next = value => {
            emit('next', value)
        }

        const back = value => {
            emit('back', value)
        }

        const addMoreCost = () => {
            state?.additionalCosts?.items?.push({
                name: '',
                amount: 0
            })
        }
        const removeCost = index => {
            state?.additionalCosts?.items?.splice(index, 1)
            calculateAdditionalCostsTotal()
        }

        const calculateAdditionalCostsTotal = () => {
            store.dispatch('Production/setProduceAdditionalCostsTotal')
            state.additionalCosts.total = produceAdditionalCosts?.value?.input?.total || state.additionalCosts.total
        }

        onMounted(() => {
            store.dispatch('Settings/getBankAccounts')
            state.additionalCosts = produceAdditionalCosts?.value || state.additionalCosts
        })

        return {
            state,
            expenses,
            bankAccounts,
            next,
            back,
            addMoreCost,
            removeCost,
            calculateAdditionalCostsTotal
        }
    }
}
</script>

<style scoped></style>
