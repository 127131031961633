<template>
    <div class="w-90 center mt4-l mt3 ">
        <template v-if="state.processing"><Spinner /></template>
        <div class="flex flex-wrap justify-between pa3 pv4" style=" font-size: 18px">
            <div class="font-w2 pb3 pb0-l">Production Summary</div>
            <div class="f5">Production ID: {{ reference }}</div>
        </div>

        <!-- Inputs Bar code , SKU, Name ,Qty , Measurement and Co -->
        <div v-if="produce?.output?.products?.length && produce.output.products[0].name !== ''">
            <div class="pa3 b text-uppercase" style="color: #132c8c;">Products</div>
            <div
                v-for="(data, index) in produce.output.products"
                :key="index"
                class="pa3 flex flex-wrap items-center justify-between w-50-l w-100"
            >
                <div class="b">{{ data.name }} ({{ data.quantity }})</div>
                <!-- <div class="b">{{ formatAmount(data.quantity * data.price) }}</div> -->
            </div>
            <div class="pa3">{{ produce.output.description }}</div>
        </div>

        <div v-if="produce?.input?.rawMaterials?.length && produce.input.rawMaterials[0].name !== ''">
            <div class="pa3 b text-uppercase" style="color: #132c8c;">Input</div>
            <div
                v-for="(data, index) in produce.input.rawMaterials"
                :key="index"
                class="pa3 flex flex-wrap items-center justify-between w-50-l w-100"
            >
                <div class="b">{{ data.name }} ({{ data.quantity }})</div>
                <div class="b">
                    {{
                        formatAmount(data.quantity * data.price, $store.state.Settings?.currency, $store.state.Settings?.currency)
                    }}
                </div>
            </div>
            <div class="pa3">{{ produce.input.description }}</div>
        </div>

        <div v-if="produce?.additionalCosts?.items?.length && produce.additionalCosts.items[0].name !== ''">
            <div class="pa3 b text-uppercase" style="color: #132c8c;">Other Cost</div>
            <div
                v-for="(data, index) in produce.additionalCosts.items"
                :key="index"
                class="pa3 flex flex-wrap items-center justify-between w-50-l w-100"
            >
                <div>
                  <div class="b pa1">{{ data.name }}</div>
                  <div>{{ data.description }}</div>
                </div>
                <div class="b">{{ formatAmount(data.amount, $store.state.Settings?.currency) }}</div>
            </div>
        </div>

        <div>
            <div class="pa3 flex flex-wrap items-center justify-between w-50-l w-100">
                <div class="b" style="color: #132c8c;">Total production cost (raw material + additional cost)</div>
                <div class="b">
                    {{ formatAmount(produce?.input?.total + produce?.additionalCosts?.total, $store.state.Settings?.currency) }}
                </div>
            </div>
        </div>
        <div v-if="produce?.output?.products?.length && produce.output.products[0].name !== ''">
            <div class="pa3 b text-uppercase" style="color: #132c8c;">Average Cost Per Product</div>
            <table class="table radius8" cellspacing="0">
                <tr style="background-color: #F4F7FF;">
                    <th class="tl borderTop">Product Name</th>
                    <th class="tl borderTop">QTY</th>
                    <th class="tl borderTop">Unit Cost of Production</th>
                    <th class="tl borderTop">Default Selling Price</th>
                    <th class="tl borderTop">New Selling Price</th>
                </tr>
                <tr v-for="(produce, index) in produce.output.products" :key="index">
                    <td class="borderTop">{{ produce.name }}</td>
                    <td class="borderTop">{{ formatQuantity(produce.quantity) }}</td>
                    <td class="borderTop">
                        {{
                            formatAmount(
                                (produce?.input?.total + produce?.additionalCosts?.total) / produce.quantity,
                                $store.state.Settings?.currency
                            )
                        }}
                    </td>
                    <td class="borderTop">{{ formatAmount(state.defaultSellingPrice, $store.state.Settings?.currency) }}</td>
                    <td class="borderTop">
                        <input
                            type="number"
                            class="product-input radius8"
                            v-model="produce.price"
                            placeholder="0.00"
                        />
                    </td>
                </tr>
            </table>
        </div>

        <!-- creat transaction & cancel Transactions button -->
        <div class="flex justify-between pt5-l pt4">
            <a href="" class="b pt2" @click.prevent="back(3)">&larr; Back to other production input</a>
            <div class="pa3 flex">
                <router-link :to="{ name: 'ProductionNew' }">
                    <button class="btn2" :disabled="state.processing">Cancel</button>
                </router-link>
                <a href="#">
                    <button class="ml2" @click="submit" :disabled="state.processing">
                        {{ state.processing ? 'Processing, please wait...' : 'Submit' }}
                    </button>
                </a>
            </div>
        </div>
        <!-- creat transaction & cancel Transactions button -->
    </div>
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { formatAmount, formatQuantity } from '@/utils/lib'
import Spinner from '@/components/Spinner'

export default {
    name: 'ProduceSummary',
    components: { Spinner },
    props: { reference: String },

    setup(_, { emit }) {
        const store = useStore()
        const produce = computed(() => store?.state.Production.produce)

        const state = reactive({
            defaultSellingPrice: 0,
            processing: false
        })

        const back = value => {
            emit('back', value)
        }

        const submit = () => {
            state.processing = true
            store.dispatch('Production/saveProduce', produce.value).then(() => {
                state.processing = false

            })
        }

        onMounted(() => {
            state.defaultSellingPrice = produce.value.output.products[0].price
        })

        return {
            state,
            produce,
            back,
            submit,
            formatAmount,
            formatQuantity
        }
    }
}
</script>

<style scoped>
.borderTop{
    border-top: none !important;
}
</style>
