<template>
    <span>
        <input 
            :id="id" 
            type="checkbox" 
            v-model="model" 
            :value="value" 
        /> 
        <label :for="id" class="mr2 pa2 ba b--dashed br3">{{ label }}</label>
    </span>
</template>

<script>
export default {
    props: {
        modelValue: { type: [Array, Boolean] },
        value: { type: [Boolean, Object] },
        label: { type: String },
        id: { type: Number }
    },
    computed: {
        model: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>
<style>
input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
}

span input[type='checkbox']:checked + label {
    color: #fff;
    background-color: #132c8c;
}
</style>
