<template>
    <div class="w-70-l center mt4-l mt3 " style="background-color: #FBFCFE;">
        <div class="flex flex-wrap justify-between pa3 pv4" style=" font-size: 18px">
            <div class="pb3 pb0-l">
                <span class="font-w2 " style="color: #132c8c;">Finished Products from Batch</span><br>
               <div class="w-100"> Input the list of items produced during this production batch and the quantity</div>
            </div>
            <div class="f5">Production ID: {{ reference }}</div>
        </div>
        <!-- Inputs Bar code , SKU, Name ,Qty , Measurement and Co -->
        <div v-for="(data, index) in state.output.products" :key="index" class="pa3 flex flex-wrap items-center gap16" >
            <div class="flex flex-column w-40-l w-100 pb3 pb0-l ">
                <label class="pb2">Name</label>
                <v-select
                    label="name"
                    :filterable="false"
                    :options="state.options"
                    @search="onSearchProduct"
                   
                    v-model="data.name"
                    @option:selected="item => selectProduct(data, item)"
                    @option:deselected="item => deselectProduct(item)"
                >
                    <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"></span>
                    </template>
                    <template #no-options="{ search, searching, loading }">
                        Type to search product..
                    </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </v-select>
            </div>
            <div class=" flex flex-column w-50-l w-90  pb3 pb0-l relative mbVieww">
                <label class="pb2">Quantity</label>
                <CurrencyInput
                    @change="() => productQuantityChange(index)"
                    class="product-input radius8"
                    v-model="data.quantity"
                    :options="numberFormat"
                    placeholder="0"
                    required
                />
                <span class="absolute " style="right: 40px; top: 31px;">{{ data.measurement || 'unit'}}</span>
            </div>
            <!-- <div class=" flex flex-column w-30-l w-90 pl2 pl0-l pb3 pb0-l">
                <label class="pb2">Measurement</label>
                <input class="product-input radius8" type="text" v-model="data.measurement" placeholder="Length" id="" required />
            </div> -->
            <div @click.prevent="removeProduct(index)" style="cursor: pointer;">
                <img :src="require('@/assets/images/trash.svg')" alt="Delete" />
            </div>
        </div>

        <div class="tr b pa3">
            <div class="">
                <a href="" @click.prevent="addMoreProducts" style="color: #132c8c"> + Add more product </a>
            </div>
        </div>

        <!-- upfront due date -->
        <div class="flex justify-between pa3">
            <div class="flex flex-column w-40-l w-100 pr2-l pr1">
                <label class="pb2">Date</label>
                <input v-model="state.output.date" class="product-input radius8" type="date" placeholder="date" required />
            </div>
            <!-- <div class="flex flex-column w-50 pl2-l pl1">
                <label class="pb2">Total</label>
                <input v-model="state.output.total" class="product-input" type="text" required />
            </div> -->
        </div>

        <!-- note -->
        <div class=" flex flex-column pa3">
            <label class="pb2">Description</label>
            <textarea
                v-model="state.output.description"
                class="pa3 radius8"
                placeholder="Enter description"
                id=""
                cols="30"
                rows="5"
            ></textarea>
        </div>

        <!-- creat transaction & cancel Transactions button -->
        <div class="flex justify-between">
            <div></div>
            <div class="pa3 flex">
                <router-link :to="{ name: 'RawMaterial' }">
                    <button class="btn2">Cancel</button>
                </router-link>
                <a href="" @click.prevent="next(2)">
                    <button class="ml2">Next</button>
                </a>
            </div>
        </div>

        <!-- creat transaction & cancel Transactions button -->
    </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment-timezone'
import { useRouter } from 'vue-router'
import CurrencyInput from '@/components/CurrencyInput'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'Products',
    components: { CurrencyInput, vSelect },
    props: { reference: String },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(_, { emit }) {
        const store = useStore()
        const produceOutput = computed(() => store?.state.Production.produce.output)
        const wipStatus = computed(() => store.state?.Settings?.accountWipStatus)

        const state = reactive({
            output: {
                products: [
                    {
                        name: '',
                        product: '',
                        price: 0,
                        quantity: 1,
                        instock: '',
                        measurement: '',
                        sku: ''
                    }
                ],
                date: moment().format('YYYY-MM-DD'),
                total: 0,
                description: ''
            },
            options: []
        })

        watch(
            () => produceOutput.value,
            () => {
                state.output = produceOutput.value
            }
        )

        const next = value => {
            emit('next', value)
        }

        const addMoreProducts = () => {
            state?.output?.products.push({
                name: '',
                product: '',
                price: 0,
                quantity: 1,
                instock: '',
                measurement: '',
                sku: ''
            })
        }

        const onSearchProduct = (search, loading) => {
            if (search.length) {
                loading(true)
                store.dispatch('Production/searchProduction', { query: search, type: 'ALL' }).then(data => {
                    state.options = data.products
                    loading(false)
                })
            }
        }

        const setProduceInput = rawMaterials => {
            rawMaterials.map(item => {
                item.initial_quantity = item.quantity
            })

            store.dispatch('Production/setProduceInput', { rawMaterials: rawMaterials })
            calculateProductsTotal()
        }

        const selectProduct = (product, item) => {
            product.name = item.name
            product.product = item._id
            product.price = item?.sellingPrice || 0
            product.instock = item?.qty_in_stock || 0
            product.measurement = item.measurement
            product.sku = item?.sku || ''
            if (wipStatus.value) {
                const rawMaterials = []
                store.dispatch('Production/getWipRawMaterials').then(data => {
                    data.forEach(rawMaterial => {
                        rawMaterials.push({
                            id: rawMaterial._id,
                            name: rawMaterial.name,
                            quantity: 1,
                            price: rawMaterial?.purchase_avg || rawMaterial?.buyingPrice || 0,
                            measurement: rawMaterial.measurement,
                            wip: rawMaterial.wip
                        });
                    });
                    setProduceInput(rawMaterials)
                });
            } else {
                const rawMaterials = item?.rawMaterials || [{
                        name: '',
                        product: '',
                        price: 0,
                        quantity: 1,
                        instock: '',
                        measurement: '',
                        sku: ''
                }]

                setProduceInput(rawMaterials)
            }
        }

        const deselectProduct = (product, item) => {
            product.name = ''
            product.quantity = ''
            product.measurement = ''
            product.instock = 0
            product.sku = ''
            calculateProductsTotal()
        }
        const removeProduct = index => {
            state?.output?.products?.splice(index, 1)
            calculateProductsTotal()
        }

        const productQuantityChange = index => {
            const value = state.output.products[index].quantity
            store.dispatch('Production/impactProduceInputQuantities', parseFloat(value))
            calculateProductsTotal()
        }

        const calculateProductsTotal = () => {
            store.dispatch('Production/setProduceOutputTotal')
            state.output.total = produceOutput?.value?.output?.total || state.output.total
        }

        onMounted(() => {
            state.output = produceOutput?.value || state.output
            store.dispatch('Settings/getAccountSettings')
        })

        return {
            state,
            produceOutput,
            wipStatus,
            next,
            addMoreProducts,
            onSearchProduct,
            selectProduct,
            deselectProduct,
            removeProduct,
            productQuantityChange,
            calculateProductsTotal
        }
    }
}
</script>

<style scoped>
.radius8{
    border-radius: 8px !important;
    border: 1px solid #E3E8EE !important;
}

@media screen and (max-width: 500px) {
    .mbVieww{
        width: 80% !important;
    }
}
</style>
