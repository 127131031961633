<template>
    <div class="w-80 center mt4-l mt3" style="background-color: #fbfcfe">
        <div class="flex justify-between pa3 pv4" style="font-size: 18px">
            <div class="w-50 pb3 pb0-l">
                <span class="font-w2 " style="color: #132c8c">Raw Materials Used </span><br />
                <div class="w-100">
                    List the products used as raw materials to produce the finished products and their quantity and current cost
                    price
                </div>
            </div>
            <div class="f5">Production ID: {{ reference }}</div>
        </div>

        <div
            v-for="(data, index) in state.input.rawMaterials"
            :key="index"
            class="pa3 flex flex-wrap items-center justify-between"
        >
            <div class="flex flex-column w-30-l w-50 pb3 pb0-l">
                <label class="pb2 b">Name</label>
                <v-select
                    label="name"
                    :filterable="false"
                    :options="state.options"
                    @search="onSearchRawMaterial"
                    v-model="data.name"
                    @option:selected="(item) => selectRawMaterial(data, item)"
                    @option:deselected="(item) => deselectRawMaterial(item)"
                >
                    <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"></span>
                    </template>
                    <template #no-options="{ search, searching, loading }"> Type to search raw material.. </template>
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }}
                        </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                            {{ option.name }}
                        </div>
                    </template>
                </v-select>
            </div>
            <div class="flex flex-column w-20-l pl2 pl0-l w-50 pb3 pb0-l">
                <label class="pb2 b">Unit Cost</label>
                <CurrencyInput
                    @input="calculateRawMaterialsTotal"
                    class="product-input radius8"
                    v-model="data.price"
                    :options="numberFormat"
                    placeholder="0"
                    required
                />
            </div>
            <div class="flex flex-column w-20-l w-50 pb3 pb0-l relative">
                <label class="pb2 b">Quantity</label>
                <CurrencyInput
                    @change="quantityChange(index, data)"
                    class="product-input radius8"
                    v-model="data.quantity"
                    :options="numberFormat"
                    placeholder="0"
                    required
                />
                <span class="absolute" style="right: 40px; top: 31px">{{ data.measurement }}</span>
            </div>
            <!-- <div class="flex flex-column w-30-l w-50 pl2 pl0-l pb3 pb0-l">
                <label class="pb2">Measurement</label>
                <input class="product-input" type="text" v-model="data.measurement" placeholder="Length" required />
            </div> -->
            <div class="flex flex-column w-20-l w-50 pl2 pl0-l pb3 pb0-l">
                <label class="pb2 b">Total</label>
                <input class="product-input radius8" type="text" :value="(data.price * data.quantity).toFixed(2)" disabled />
            </div>
            <div @click.prevent="removeRawMaterial(index)" style="cursor: pointer">
                <img :src="require('@/assets/images/trash.svg')" alt="Delete" />
            </div>
        </div>

        <div v-if="!wipStatus" class="tr b pa3">
            <div class="">
                <a href="" @click.prevent="addMoreRawMaterials" style="color: #132c8c"> + Add more raw material </a>
            </div>
        </div>
        <div v-if="wipStatus" class="flex justify-end pa3">
            <span v-for="(data, index) in state.wipInput.rawMaterials" :key="data.id">
                <CustomCheckbox
                    :id="index"
                    :label="data.name"
                    v-model="state.input.rawMaterials"
                    :value="data"
                    @click="calculateRawMaterialsTotal"
                />
            </span>
        </div>

        <!-- Input raw material total cost -->
        <div class="flex justify-between pa3 w-100">
            <div class="flex flex-column w-100 pr2-l pr1">
                <label class="pb2 b">Total</label>
                <input
                    v-model="state.input.total"
                    class="product-input radius8 w-100"
                    type="text"
                    placeholder="0.00"
                    required
                    disabled
                />
            </div>
            <!-- <div class="flex flex-column w-50 pl2-l pl1"></div> -->
        </div>

        <!-- note -->
        <div class="flex flex-column pa3">
            <label class="pb2 b">Description</label>
            <textarea
                v-model="state.input.description"
                class="pa3 radius8"
                placeholder="Enter description"
                cols="30"
                rows="5"
            ></textarea>
        </div>

        <div class="flex justify-between">
            <div>
                <a href="pa3" class="b pt2" @click.prevent="back(1)">&larr; Back to production output</a>
            </div>
            <div class="pa3 flex">
                <router-link :to="{ name: 'RawMaterial' }">
                    <button class="btn2">Cancel</button>
                </router-link>
                <a href="" @click.prevent="next(3)">
                    <button class="ml2">Next</button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import CurrencyInput from '@/components/CurrencyInput'
import CustomCheckbox from '@/components/CustomCheckbox'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    name: 'InputRawMaterial',
    components: { CurrencyInput, vSelect, CustomCheckbox },
    props: { reference: String },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true,
            },
        }
    },

    setup(_, { emit }) {
        const store = useStore()
        const produceInput = computed(() => store?.state.Production.produce.input)
        const wipStatus = computed(() => store.state?.Settings?.accountWipStatus)

        const state = reactive({
            input: {
                rawMaterials: [
                    {
                        name: '',
                        product: '',
                        price: 0,
                        quantity: 1,
                        instock: '',
                        measurement: '',
                        sku: '',
                    },
                ],
                total: 0,
                description: '',
            },
            wipInput: {
                rawMaterials: [
                    {
                        name: '',
                        product: '',
                        price: 0,
                        quantity: 1,
                        instock: '',
                        measurement: '',
                        sku: '',
                    },
                ],
                total: 0,
                description: '',
            },
            options: [],
        })

        watch(
            () => produceInput.value,
            () => {
                if (!wipStatus.value) {
                    state.input.rawMaterials = produceInput.value
                }
            }
        )

        watch(
            () => state.input.rawMaterials,
            () => {
                if (wipStatus.value) {
                    store
                        .dispatch('Production/setProduceInput', {
                            rawMaterials: state.input.rawMaterials,
                        })
                        .then((_) => {
                            store.dispatch('Production/setProduceInputTotal', { rawMaterials: state.input.rawMaterials })
                        })
                    state.input.total = state.input.rawMaterials.reduce((prevVal, currValue) => {
                        prevVal += parseInt((currValue?.quantity || 0) * (currValue?.price || 0))
                        return prevVal
                    }, 0)
                }
            }
        )

        const next = (value) => {
            emit('next', value)
        }

        const back = (value) => {
            emit('back', value)
        }

        const addMoreRawMaterials = () => {
            state?.input?.rawMaterials.push({
                name: '',
                product: '',
                quantity: 1,
                instock: '',
                price: 0,
                measurement: '',
                sku: '',
            })
        }

        const onSearchRawMaterial = (search, loading) => {
            if (search.length) {
                loading(true)
                if (wipStatus.value) {
                    store.dispatch('Production/getWipRawMaterials').then((data) => {
                        state.options = data
                        loading(false)
                    })
                } else {
                    store.dispatch('Production/searchProduction', { query: search, type: 'ALL' }).then((data) => {
                        state.options = data.products
                        loading(false)
                    })
                }
            }
        }

        const selectRawMaterial = (rawMaterial, item) => {
            rawMaterial.name = item.name
            rawMaterial.product = item._id
            rawMaterial.price = item?.purchase_avg || item?.buyingPrice || 0
            rawMaterial.instock = item?.qty_in_stock || 0
            rawMaterial.measurement = item.measurement
            rawMaterial.sku = item?.sku || ''
            calculateRawMaterialsTotal()
        }

        const deselectRawMaterial = (rawMaterial, item) => {
            rawMaterial.name = ''
            rawMaterial.quantity = 0
            rawMaterial.price = 0
            rawMaterial.instock = 0
            rawMaterial.measurement = ''
            rawMaterial.sku = ''
            calculateRawMaterialsTotal()
        }
        const removeRawMaterial = (index) => {
            state?.input?.rawMaterials?.splice(index, 1)
            calculateRawMaterialsTotal()
        }

        const calculateRawMaterialsTotal = () => {
            store.dispatch('Production/setProduceInputTotal', { rawMaterials: state.input.rawMaterials })
            state.input.total = state.input.rawMaterials.reduce((prevVal, currValue) => {
                prevVal += parseInt((currValue?.quantity || 0) * (currValue?.price || 0))
                return prevVal
            }, 0)
            // alert(state.input.total)
            // state.input.total = produceInput?.value?.total || state.input.total
            state.wipInput.total = state.input.total
        }

        const quantityChange = (index, rawMaterial) => {
            if (wipStatus.value && rawMaterial.quantity > rawMaterial.wip) {
                state.input.rawMaterials[index].quantity = parseInt(rawMaterial.wip)
            }
            calculateRawMaterialsTotal()
        }

        onMounted(() => {
            if (wipStatus.value) {
                state.wipInput = produceInput?.value || state.input
                state.input.rawMaterials = []
            } else {
                state.input = produceInput?.value || state.input
            }
            store.dispatch('Settings/getAccountSettings')
        })

        return {
            state,
            wipStatus,
            produceInput,
            next,
            back,
            addMoreRawMaterials,
            onSearchRawMaterial,
            selectRawMaterial,
            deselectRawMaterial,
            removeRawMaterial,
            calculateRawMaterialsTotal,
            quantityChange,
        }
    },
}
</script>

<style>
.vs__dropdown-toggle {
    padding: 6px;
}
.w25 {
    width: 25%;
}
</style>
